import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/node_modules/gatsby-theme-docz/src/base/Layout.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "quick-start-for-service-partners"
    }}>{`Quick Start for Service Partners`}</h1>
    <h3 {...{
      "id": "1-apply-to-the-partner-program"
    }}>{`1. Apply to the Partner Program`}</h3>
    <ul>
      <li parentName="ul">{`Once you are logged in, click on the menu at the top right, and click the menu button for the `}<inlineCode parentName="li">{`Partner Program`}</inlineCode>{`.`}</li>
      <li parentName="ul">{`Complete the form, review the program terms, and apply.`}</li>
      <li parentName="ul">{`Once you apply, we will review your application.`}</li>
    </ul>
    <h3 {...{
      "id": "2-add-payment-information"
    }}>{`2. Add Payment Information`}</h3>
    <ul>
      <li parentName="ul">{`Once you've been granted access to the Partner Program, you will need to add a credit card before you can add a client.`}</li>
      <li parentName="ul">{`Click on the menu at the top right, and click `}<inlineCode parentName="li">{`Payment Method`}</inlineCode>{`.`}</li>
      <li parentName="ul">{`Add a payment method and submit the form.`}</li>
    </ul>
    <h3 {...{
      "id": "3-add-a-client"
    }}>{`3. Add a Client`}</h3>
    <ul>
      <li parentName="ul">
        <p parentName="li">{`Click the `}<inlineCode parentName="p">{`Partners`}</inlineCode>{` button in the left menu, and then click `}<inlineCode parentName="p">{`Add Client`}</inlineCode>{`.`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`Select the Plan and provide the name of the Partner.  Other information is optional.`}</p>
        <pre parentName="li"><code parentName="pre" {...{}}>{`Note that if you first want to create a token that will count towards your partner account, you can skip this step.
`}</code></pre>
      </li>
    </ul>
    <h3 {...{
      "id": "4-add-a-notification-group"
    }}>{`4. Add a Notification Group`}</h3>
    <p>{`A Notification Group is the list of email address to which security alerts will be sent. Before you can create a token, at least one Notification Group is required.`}</p>
    <ul>
      <li parentName="ul">{`Click the `}<inlineCode parentName="li">{`Notification Group`}</inlineCode>{` button in the left menu to expand the menu.`}</li>
      <li parentName="ul">{`Click the `}<inlineCode parentName="li">{`New Notification Group`}</inlineCode>{` button.`}</li>
      <li parentName="ul">{`Enter Enter the name of the group, and enter all the email addresses you want. Click the `}<inlineCode parentName="li">{`Add`}</inlineCode>{` button for each.`}</li>
      <li parentName="ul">{`Click `}<inlineCode parentName="li">{`Create Notification Group`}</inlineCode></li>
    </ul>
    <h3 {...{
      "id": "5-create-the-token"
    }}>{`5. Create the Token`}</h3>
    <ul>
      <li parentName="ul">{`Click the `}<inlineCode parentName="li">{`Token`}</inlineCode>{` button in the left menu to expand the menu.`}</li>
      <li parentName="ul">{`Click the `}<inlineCode parentName="li">{`New Token`}</inlineCode>{` button.`}</li>
      <li parentName="ul">{`Complete the form and create the token.`}</li>
      <li parentName="ul">{`Once created, a `}<inlineCode parentName="li">{`Go To New Token`}</inlineCode>{` button will appear next to the `}<inlineCode parentName="li">{`Create Token`}</inlineCode>{` will bring you directly to the token details. Click it.`}</li>
      <li parentName="ul">{`Activate and deploy the token.  The steps to deploy will vary depending on the type of token.  See the token types for token specific implementation recommendations.`}</li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      